import { withAuth } from '../components/withAuth';
import type { NextPage } from 'next';
import NextLink from 'next/link';

const Index: NextPage = () => {
  return (
    <section className="max-w-screen-xl mx-auto p-4 grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* LEFT COL */}
      <div className="flex flex-col gap-6 order-2 md:order-1 md:col-span-2">
        {/* ALERT */}
        <div
          id="alert-additional-content-4"
          className="p-4 rounded-lg shadow-md text-blue-600 border border-blue-400 bg-white"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-4 h-4 me-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium">
              Next Delivery: <b>2/29/24</b>
            </h3>
          </div>
          <div className="mt-2 mb-4 text-sm text-gray-700">
            More info about this alert goes here. This example text is going to
            run a bit longer so that you can see how spacing within an alert
            works with this kind of content.
          </div>
          <div className="flex">
            <button
              type="button"
              className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center"
            >
              <svg
                className="me-2 h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 14"
              >
                <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
              </svg>
              View more
            </button>
            <button
              type="button"
              className="text-blue-600 bg-transparent border border-blue-600 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center"
              data-dismiss-target="#alert-additional-content-4"
              aria-label="Close"
            >
              Dismiss
            </button>
          </div>
        </div>

        {/* AMOUNT DUE */}
        <div id="amount-due" className="p-4 rounded-lg bg-white shadow-md">
          <div className="pb-4 mb-4 border-b border-stroke ">
            <p className="text-lg font-semibold">Amount Due</p>
            <p className="text-sm">
              Please note that Total Amount Due may not reflect any pending
              payments or credits on your account.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-lg font-semibold text-center">
              Total Amount Due
            </p>
            <p className="text-2lg text-center text-green-600">$0.00</p>
            <NextLink href="/payment" passHref>
              <button
                type="button"
                className="focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text px-5 py-2.5 "
              >
                Make a Payment
              </button>
            </NextLink>
            <p className="text-sm">
              or{' '}
              <a className="text-blue-600" href="#">
                Set up automatic payments
              </a>
            </p>
          </div>
        </div>

        {/* OPEN WORK ORDERS */}
        <div
          id="open-work-orders"
          className="p-4 rounded-lg bg-white shadow-md"
        >
          <div className="pb-4 mb-4 border-b border-stroke">
            <p className="text-lg font-semibold">Requested Deliveries</p>
          </div>
          <div className="relative rounded-lg overflow-x-auto shadow-md sm:rounded">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-5 py-3">
                    Created On
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Equipment
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Delivery Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b hover:bg-gray-50">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    3/24/24
                  </th>
                  <td className="px-5 py-4">Horizontal 120</td>
                  <td className="px-5 py-4">Heating Oil</td>
                  <td className="px-5 py-4">100 gal</td>
                  <td className="px-5 py-4">Call Recieved</td>
                  <td className="px-5 py-4">TBD</td>
                </tr>
                <tr className="bg-white border-b hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    2/18/24
                  </th>
                  <td className="px-5 py-4">Horizontal 500</td>
                  <td className="px-5 py-4">Propane</td>
                  <td className="px-5 py-4">250 gal</td>
                  <td className="px-5 py-4">Request Processed</td>
                  <td className="px-5 py-4">2/29/24</td>
                </tr>
                <tr className="bg-white hover:bg-gray-50">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    1/12/24
                  </th>
                  <td className="px-5 py-4">Horizontal 2000</td>
                  <td className="px-5 py-4">Diesel</td>
                  <td className="px-5 py-4">1000 gal</td>
                  <td className="px-5 py-4">En Route</td>
                  <td className="px-5 py-4">1/20/24</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* DELIVERY HISTORY */}
        <div
          id="delivery-history"
          className="p-4 rounded-lg bg-white shadow-md"
        >
          <div className="pb-4 mb-4 border-b border-stroke ">
            <p className="text-lg font-semibold">Completed Deliveries</p>
          </div>
          <div className="relative rounded-lg overflow-x-auto shadow-md sm:rounded">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                <tr>
                  <th scope="col" className="px-5 py-3">
                    Delivered On
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Equipment
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-5 py-3">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    12/18/2023
                  </th>
                  <td className="px-5 py-4">Horizontal 2000</td>
                  <td className="px-5 py-4">Diesel</td>
                  <td className="px-5 py-4">1000 gal</td>
                </tr>
                <tr className="bg-white border-b   hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    11/13/2023
                  </th>
                  <td className="px-5 py-4">Horizontal 500</td>
                  <td className="px-5 py-4">Propane</td>
                  <td className="px-5 py-4">250 gal</td>
                </tr>
                <tr className="bg-white  hover:bg-gray-50 ">
                  <th
                    scope="row"
                    className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    10/9/2023
                  </th>
                  <td className="px-5 py-4">Horizontal 120</td>
                  <td className="px-5 py-4">Heating Oil</td>
                  <td className="px-5 py-4">100 gal</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* RIGHT COL */}
      <div className="flex flex-col gap-6 order-1 md:order-2 md:col-span-1">
        {/* ACCOUNT DETAILS */}
        <div
          id="account-details"
          className="p-6 rounded-lg shadow-md border border-stroke bg-white shadow-default"
        >
          <div className="pb-4 mb-4 border-b border-stroke ">
            <p className="text-xl leading-8 font-semibold">Account #: 190089623</p>
            <p className="text-sm text-blue-600">
              100 River Avenue Aniak, Alaska 99557
            </p>
          </div>

          <div className="flex flex-col gap-y-6">
            {/* TODO: Pass in Okta info */}
            {/* <div>
              <p className="text-sm text-gray-600">Account Name</p>
              <p className="font-semibold text-blue-600">John Adams</p>
            </div>

            <div>
              <p className="text-sm text-gray-600">Email Address</p>
              <p className="font-semibold text-blue-600">
                john.adams@gmail.com
              </p>
            </div> */}

            <div>
              <p className="text-sm text-gray-600">Phone Number</p>
              <p className="font-semibold text-blue-600">787-793-8575</p>
            </div>

            <div>
              <p className="text-sm text-gray-600">Customer Since</p>
              <p className="font-semibold text-blue-600">10/01/2023</p>
            </div>

            <div>
              <p className="text-sm text-gray-600">Account Balance</p>
              <p className="font-semibold text-blue-600">$0.00</p>
            </div>
          </div>
        </div>

        {/* MENU */}
        <div
          id="menu"
          className="px-3 py-4 shadow-md overflow-y-auto rounded-lg border border-stroke bg-white   "
        >
          <ul className="space-y-2">
            {/* <li>
              <a
                href="#"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="ml-3">Make a Payment</span>
              </a>
            </li> */}
            <li>
              <a
                href="#"
                target="_blank"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 "
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Account History
                </span>
                {/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full">
                Pro
              </span> */}
              </a>
            </li>
            <li>
              <a
                href="#"
                target="_blank"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.617 2.076a1 1 0 0 1 1.09.217L8 3.586l1.293-1.293a1 1 0 0 1 1.414 0L12 3.586l1.293-1.293a1 1 0 0 1 1.414 0L16 3.586l1.293-1.293A1 1 0 0 1 19 3v18a1 1 0 0 1-1.707.707L16 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L12 20.414l-1.293 1.293a1 1 0 0 1-1.414 0L8 20.414l-1.293 1.293A1 1 0 0 1 5 21V3a1 1 0 0 1 .617-.924ZM9 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Zm0 4a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Statements
                </span>
                <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full  ">
                  3
                </span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
              >
                <svg
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75  group-hover:text-gray-900 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">Locations</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
              >
                <svg
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"
                >
                  <g>
                    <path d="m227.53 689.06h244.95c56.273 0 102.06-45.785 102.06-102.06h-449.06c0 56.273 45.773 102.06 102.05 102.06z" />
                    <path d="m350 446.39c-26.262 0-47.633-21.371-47.633-47.633 0-9.4492 2.7656-18.582 7.9844-26.414l39.648-59.312 39.637 59.324c5.2266 7.8203 7.9844 16.953 7.9844 26.414 0.011718 26.25-21.359 47.621-47.621 47.621z" />
                    <path d="m574.52 562.86v-286.56c0-49.285-35.121-90.52-81.648-100v-124.54h20.41c11.266 0 20.41-9.1328 20.41-20.41 0.011719-11.277-9.1328-20.41-20.398-20.41h-326.59c-11.266 0-20.41 9.1328-20.41 20.41s9.1445 20.41 20.41 20.41h20.41v124.52c-46.527 9.4844-81.648 50.719-81.648 100v286.56h449.06zm-224.52-75.645c-48.77 0-88.453-39.68-88.453-88.453 0-17.555 5.1406-34.531 14.875-49.086l56.613-84.723c7.5703-11.332 26.371-11.332 33.938 0l56.613 84.723c9.7227 14.57 14.863 31.543 14.863 49.098 0.003906 48.762-39.68 88.441-88.449 88.441zm-102.06-435.45h204.12v122.48h-81.648v-40.82h20.41c11.266 0 20.41-9.1328 20.41-20.41s-9.1445-20.41-20.41-20.41h-81.648c-11.266 0-20.41 9.1328-20.41 20.41s9.1445 20.41 20.41 20.41h20.41v40.82h-81.648l-0.003906-122.48z" />
                  </g>
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">Tanks</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 "
              >
                <svg
                  viewBox="0 0 700 700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="flex-shrink-0 w-7 h-7 text-gray-500 transition duration-75 group-hover:text-gray-900"
                >
                  <g>
                    <path d="m688.98 301.75-29.852-73.293c-5.25-12.969-17.707-21.309-31.707-21.309h-125.48c-18.84 0-34.176 15.34-34.176 34.176v181.28l-426.9-0.003907c-11.219 0-20.383 9.1602-20.383 20.383v39.531c0 11.324 9.1602 20.484 20.383 20.484h28.516c0.51563-38.809 32.219-70.102 71.133-70.102 38.91 0 70.617 31.293 71.133 70.102h9.1602c0.51562-38.809 32.219-70.102 71.133-70.102 38.91 0 70.617 31.293 71.133 70.102h144.53c-0.69922-11.895 5.0234-31.75 17.5-45.602 5.0586-6.1836 15.781-13.906 16.984-14.207 10.707-6.5898 23.266-10.293 36.648-10.293s25.941 3.707 36.648 10.293c0.64453 0.16016 3.2773 2.0742 3.8086 2.4688 13.59 7.9219 27.207 26.09 30.473 52.191 0.20703 1.75 0.30859 3.3984 0.20703 5.1484h19.457c11.219 0 20.383-9.1602 20.383-20.484v-176.96c-0.003907-1.3359-0.21094-2.5742-0.72656-3.8086zm-40.453 50.957h-106.24v-104.38h80.5l25.734 63.207z" />
                    <path d="m191.16 504.13c0 3.1914-0.30859 6.1758-0.92578 9.1602-4.2188 23.676-24.91 41.484-49.723 41.484-24.809 0-45.5-17.809-49.719-41.484-0.61719-2.9844-0.92578-5.9727-0.92578-9.1602 0-28 22.648-50.648 50.648-50.648s50.645 22.648 50.645 50.648z" />
                    <path d="m342.59 504.13c0 3.1914-0.30859 6.1758-0.92578 9.1602-4.2227 23.676-24.91 41.484-49.723 41.484-24.809 0-45.5-17.809-49.723-41.484-0.61719-2.9844-0.92578-5.9727-0.92578-9.1602 0-28 22.648-50.648 50.648-50.648s50.648 22.648 50.648 50.648z" />
                    <path d="m629.38 504.13c0 3.1914-0.30859 6.1758-0.92578 9.1602-4.2188 23.676-24.91 41.484-49.723 41.484-24.809 0-45.5-17.809-49.719-41.484-0.61719-2.9844-0.92578-5.9727-0.92578-9.1602 0-28 22.648-50.648 50.648-50.648 27.996 0 50.645 22.648 50.645 50.648z" />
                    <path d="m322.21 188.21h-46.297v-22.559c0-11.266-9.168-20.426-20.434-20.426h-60.816c-11.266 0-20.426 9.1641-20.426 20.426v22.559h-56.469c-59.191 0-107.47 48.176-107.47 107.47s48.281 107.47 107.47 107.47h214.63c59.191 0 107.37-48.176 107.37-107.47 0-57.234-54.867-107.47-117.56-107.47zm-127.55-22.398 60.66-0.16016v22.559h-60.547zm30.062 180.52c-21 0-38.09-16.984-38.09-37.984s38.09-63.309 38.09-63.309 37.984 42.309 37.984 63.309-17.09 37.984-37.984 37.984z" />
                  </g>
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">Equipment</span>
              </a>
            </li>
          </ul>
        </div>

        {/* RECENT ACTIVITY */}
        <div
          id="recent-activity"
          className="p-4 shadow-md rounded-lg border border-stroke bg-white shadow-default  "
        >
          <div className="pb-4 mb-2 border-b border-stroke ">
            <p className="text-xl font-semibold">Recent Activity</p>
          </div>

          <div className="p-2">
            <p className="font-semibold">Last Delivery:</p>
            <p className="leading-7 text-sm">12/18/23</p>
          </div>
          <div className="p-2">
            <p className="leading-7 font-semibold">Last Payment:</p>
            <p className="leading-7 text-sm">12/19/24</p>
            <p className="leading-7 text-sm">$282.09</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withAuth(Index);
