import { ComponentType } from 'react';
import { Loading } from './Loading';
import { useOktaAuth } from './OktaContext';

export function withAuth(WrappedComponent: ComponentType<unknown>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  function WithAuth() {
    const { authState } = useOktaAuth();
    console.log('Auth state in WithAuth:', authState);

    if (authState?.isAuthenticated) {
      console.log('User is authenticated, rendering WrappedComponent:', WrappedComponent.name);
      return <WrappedComponent />;
    }
    console.log('User is not authenticated, showing loading');
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <Loading />
      </div>
    );
  }

  WithAuth.displayName = `WithAuth(${displayName})`;

  return WithAuth;
}
